<template>
  <b-overlay
    :show="loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      v-if="!loading || sales"
      no-body
    >
      <b-card-header class="align-items-baseline">
        <b-card-title>
          <h4 class="vh-center">
            Sales by country
          </h4>
        </b-card-title>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body class="pb-0 position-relative">

        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series"
        />
        <b-overlay
          :show="false"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              There is not orders yet.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, VBPopover, VBTooltip, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BButton,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    sales: Array,
    loading: Boolean,
  },
  data() {
    return {
      warning: null,
      loaded: false,
      selection: 'all',
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    countries() {
      const result = []
      const others = { name: 'Others', profit: 0, code: 'others' }
      this.sales.forEach(country => {
        if (country.result_sum < 30000) others.profit += Number(country.result_sum)
        else result.push({ name: country.name, profit: Number(country.result_sum), code: country.code2 })
      })
      result.push(others)
      return result
    },
    balanceChart() {
      return {
        series: [
          {
            name: 'Profit',
            data: this.countries.map(item => item.profit),
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
          },
          colors: [$themeColors.primary, $themeColors.info, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '75%',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: this.countries.map(item => item.code),
            labels: {
              style: {
                colors: '#b9b9c3',
              },
            },
          },
          yaxis: [
            {
              labels: {
                style: {
                  colors: '#b9b9c3',
                },
              },
              opposite: true,
              title: {
                text: 'Total Profit',
              },
            },
          ],
          tooltip: {
            y: {
              show: true,
              formatter: value => formatCurrency(value, 'usd'),
            },
            x: {
              show: true,
              formatter: value => this.countries.find(item => value === item.code).name,
            },
          },
        },
      }
    },
  },
  methods: {
    formatCurrency,
  },
}
</script>
