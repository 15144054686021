<template>
  <div
    class="transaction-item"
  >
    <b-media no-body>
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-info"
        >
          <feather-icon
            :icon="icon"
            size="18"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          {{ title }}
        </h6>
      </b-media-body>
    </b-media>
    <div
      class="font-weight-bolder mr-2"
    >
      <b-row>
        {{ text }}
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BAvatar, BMedia, BMediaAside, BMediaBody, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BRow,
  },
  props: {
    title: String,
    text: [String, Number],
    icon: String,
  },
}
</script>
