<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Report</b-card-title>
      <b-form-group class="mt-1 float-right ml-auto mr-1">
        <h5>Date range</h5>
        <div class="d-flex">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
          />

          <b-button
            variant="outline-primary"
            :disabled="dateRange == null || loading"
            @click.prevent="onSearch"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
          <b-button
            v-clipboard:copy="getReport()"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            v-b-tooltip.hover.right="'Copy results'"
            :disabled="!result"
            class="ml-1"
            variant="outline-info"
          >
            <feather-icon
              icon="CopyIcon"
            />
          </b-button>
        </div>
      </b-form-group>

    </b-card-header>
    <b-overlay :show="loading">
      <b-card-body v-if="!result">
        Please select date range (UTC)
      </b-card-body>
      <b-card-body v-else>
        <b-row>
          <b-col
            lg="6"
            sm="12"
          >
            <media-item
              title="Avg payout time"
              :text="formatSecToTime(result.avg_payout_time)"
              icon="CreditCardIcon"
            />
            <media-item
              title="Biggest payout"
              :text="formatCurrency(result.biggest_payout, 'USD')"
              icon="DollarSignIcon"
            />
            <media-item
              title="AVG payout"
              :text="formatCurrency(result.avg_payout, 'USD')"
              icon="DollarSignIcon"
            />
            <media-item
              title="Highest RR"
              :text="`${result.highest_rr}%`"
              icon="PercentIcon"
            />
            <media-item
              title="Total registered"
              :text="result.total_registered"
              icon="UserPlusIcon"
            />
          </b-col>
          <b-col
            lg="6"
            sm="12"
          >
            <media-item
              title="Fastest phase 2"
              :text="formatSecToTime(result.fastest_phase2)"
              icon="ClockIcon"
            />
            <media-item
              title="Fastest evaluation 2 phase accounts"
              :text="formatSecToTime(result.fastest_evaluation)"
              icon="ClockIcon"
            />
            <media-item
              title="Fastest evaluation 3 phase accounts"
              :text="formatSecToTime(result.fastest_evaluation_3phase)"
              icon="ClockIcon"
            />
            <media-item
              title="Total funded"
              :text="formatCurrency(result.total_funded, 'USD')"
              icon="DollarSignIcon"
            />
          </b-col>
        </b-row>
        <hr class="mx-50 my-2">
        <h4>Biggest trade</h4>
        <b-row>
          <b-col
            lg="3"
            sm="6"
          >
            User: <strong>{{ result.biggest_trade.email }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Symbol: <strong>{{ result.biggest_trade[0].symbol }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Type: <strong>{{ result.biggest_trade[0].type === 0 ? 'BUY' : 'SELL' }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Open time: <strong>{{ formatDateTime(result.biggest_trade[0].open_datetime) }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Close time: <strong>{{ formatDateTime(result.biggest_trade[0].close_datetime) }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Open price: <strong>{{ result.biggest_trade[0].open_price }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Close price: <strong>{{ result.biggest_trade[0].close_price }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Volume: <strong>{{ result.biggest_trade[0].volume }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Commission: <strong>{{ result.biggest_trade[0].commission }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Swap: <strong>{{ result.biggest_trade[0].swap }}</strong>
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            Profit: <strong>{{ formatCurrency(result.biggest_trade[0].profit, result.biggest_trade.currency_label) }}</strong>
          </b-col>
        </b-row>
        <hr class="mx-50 my-2">
        <h4>Top 5 withdrawals</h4>
        <div
          v-for="(item, key) in result.highest_withdrawals"
          :key="key"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside v-if="!isMobile">
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <b-img
                  v-if="item.profile_image"
                  class="w-100"
                  :src="getProfileImage(item)"
                />
                <feather-icon
                  v-else
                  icon="UserIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">

                <b-link :to="{name: 'user-detail', params: {id: item.id}}">
                  {{ item.firstName }} {{ item.lastName }}
                </b-link>
                |
                <b-link :to="{name: 'account-detail', params: {id: item.account_id}}">
                  {{ item.account_id }}
                </b-link>
              </h6>
              <small>{{ item.email }} | {{ item.country }}</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder mr-2"
          >
            <b-row>
              {{ formatCurrency(item.result_sum, item.currency || 'usd') }}
            </b-row>
          </div>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  VBTooltip,
  BFormGroup,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BLink,
  BAvatar,
  BImg,
  BMediaAside, BMedia, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { get } from 'vuex-pathify'
import MediaItem from '@/components/page/MediaItem.vue'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import StatisticsAPI from '@/api/statistics'
import copyToClipboard from '@/mixins/copyToClipboard'

export default {
  components: {
    BMediaBody,
    BMedia,
    BMediaAside,
    BImg,
    BAvatar,
    BLink,
    BCard,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    flatPickr,
    BButton,
    BRow,
    BCol,
    MediaItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [copyToClipboard],
  data() {
    return {
      dateRange: null,
      result: null,
      loading: false,
    }
  },
  computed: {
    ...get('app', ['isMobile']),
  },
  methods: {
    formatCurrency,
    formatDateTime,
    getProfileImage(item) {
      return `${process.env.VUE_APP_API_URL}users/${item.id}/avatar/${item.profile_image}`
    },
    onSearch() {
      this.loading = true
      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')
      StatisticsAPI.getStatisticReport(from, to)
        .then(response => {
          this.result = response
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatSecToTime(time) {
      const hours = Math.floor(time / 3600)
      const min = Math.floor((time - hours * 3600) / 60)
      const sec = Math.round(time - min * 60 - hours * 3600)
      return `${(hours !== 0 ? `${hours} h. ` : '')} ${(min !== 0 ? `${min} min. ` : '')} ${sec} sec.`
    },
    getReport() {
      if (!this.result) return ''

      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')
      let msg = `Report from ${from} to ${to} \n-------------------------------------------------------------------\n`
      msg += `Avg payout time: ${this.formatSecToTime(this.result.avg_payout_time)} \n`
      msg += `AVG payout: ${this.formatCurrency(this.result.avg_payout, 'USD')} \n`
      msg += `Highest RR: ${this.result.highest_rr}% \n`
      msg += `Total registered: ${this.result.total_registered} \n`
      msg += `Fastest phase 2: ${this.formatSecToTime(this.result.fastest_phase2)} \n`
      msg += `Fastest evaluation 2 phase accounts: ${this.formatSecToTime(this.result.fastest_evaluation)} \n`
      msg += `Fastest evaluation 3 phase accounts: ${this.formatSecToTime(this.result.fastest_evaluation_3phase)} \n`
      msg += `Total funded: ${formatCurrency(this.result.total_funded, 'USD')} \n`
      msg += '-------------------------------------------------------------------\nBiggest trade: \n'
      msg += `User: ${this.result.biggest_trade.email} \n`
      msg += `Symbol: ${this.result.biggest_trade[0].symbol} \n`
      msg += `Type: ${this.result.biggest_trade[0].type === 0 ? 'BUY' : 'SELL'} \n`
      msg += `Open time: ${this.formatDateTime(this.result.biggest_trade[0].open_datetime)} \n`
      msg += `Close time: ${this.formatDateTime(this.result.biggest_trade[0].close_datetime)} \n`
      msg += `Open price: ${this.result.biggest_trade[0].open_price} \n`
      msg += `Close price: ${this.result.biggest_trade[0].close_price} \n`
      msg += `Volume: ${this.result.biggest_trade[0].volume} \n`
      msg += `Commission: ${this.result.biggest_trade[0].commission} \n`
      msg += `Swap: ${this.result.biggest_trade[0].swap} \n`
      msg += `Profit: ${this.formatCurrency(this.result.biggest_trade[0].profit, this.result.biggest_trade.currency_label)} \n`
      msg += '-------------------------------------------------------------------\nTop 5 withdrawals: \n'
      this.result.highest_withdrawals.forEach(w => {
        msg += `${w.email}, ${w.firstName} ${w.lastName} | ${w.country} - ${w.account_id}: ${this.formatCurrency(w.result_sum, w.currency)} \n`
      })

      return msg
    },
  },
}
</script>
<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
</style>
