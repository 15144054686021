<template>
  <b-overlay
    :show="loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Quick statistics</b-card-title>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statistics_items"
            :key="item.name"
            v-b-tooltip.hover.bottom="item.tooltip"
            class="mt-1"
            xxl="3"
            xl="3"
            lg="3"
            sm="6"
            cols="12"
            @click.prevent="this.$router.push({ name: item.route, params: { id: item.id } })"
          >
            <b-media
              no-body
              class="mb-2 mb-xxl-0"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                  :class="item.customClass"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <b-link
                    v-if="item.id"
                    :to="{name: item.route, params: {id: item.id}}"
                  >
                    {{ item.subtitle }}
                  </b-link>
                  <span v-else>{{ item.subtitle }}</span>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
  BLink,
  BOverlay, BButton,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import Ripple from 'vue-ripple-directive'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BButton,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    statistics: Object,
    loading: Boolean,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    statistics_items() {
      if (this.statistics.highest_withdrawals.length === 0 || !this.statistics.highest_spenders.length === 0) return []
      return [
        {
          name: 'highest_withdrawal',
          icon: 'DollarSignIcon',
          title: formatCurrency(this.statistics.highest_withdrawals[0].result_sum, this.statistics.highest_withdrawals[0].currency) || '-',
          subtitle: `Highest withdrawal by ${this.statistics.highest_withdrawals[0].firstName} ${this.statistics.highest_withdrawals[0].lastName} | ${this.statistics.highest_withdrawals[0].email}`,
          color: 'danger',
          customClass: 'badge-light-danger',
          tooltip: 'Highest withdrawal of all time',
          id: this.statistics.highest_withdrawals[0].account_id,
          route: 'account-detail',
        },
        {
          name: 'highest_spend',
          icon: 'CornerRightUpIcon',
          title: formatCurrency(this.statistics.highest_spenders[0].result_sum, 'usd') || '-',
          subtitle: `Highest spend sum ${this.statistics.highest_spenders[0].firstName} ${this.statistics.highest_spenders[0].lastName} | ${this.statistics.highest_spenders[0].email}`,
          color: 'success',
          customClass: 'badge-light-success',
          tooltip: 'Highest spend sum of all time',
          id: this.statistics.highest_spenders[0].id,
          route: 'user-detail',
        },
      // {
      //   name: 'lowest_withdrawal_rate',
      //   icon: 'ArrowDownIcon',
      //   title: 10000 || '-',
      //   subtitle: 'Lowest withdrawal rate',
      //   color: 'success',
      //   customClass: 'badge-light-success',
      //   tooltip: 'Actual lowest withdrawal rate',
      //   id: null,
      //   route: 'user-detail',
      // },
      // {
      //   name: 'highest_withdrawal_rate',
      //   icon: 'ArrowUpIcon',
      //   title: 10000 || '-',
      //   subtitle: 'Highest withdrawal rate',
      //   color: 'danger',
      //   customClass: 'badge-light-danger',
      //   tooltip: 'Actual highest withdrawal rate',
      //   id: null,
      //   route: 'user-detail',
      // },
      ]
    },
  },
}
</script>
<style scoped lang="scss">

.statistics-body{
  padding-top: 0!important;
}
</style>
