<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <!-- statistic -->
        <quick-statistics
          :statistics="statistics"
          :loading="loadingAll"
          @force-reload="getMainStats(true)"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="7"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <!-- sales by country -->
            <spend-per-country
              :sales="statistics.spenders_by_country"
              :loading="loadingAll"
              @force-reload="getMainStats(true)"
            />
          </b-col>
          <b-col cols="12">
            <!-- orders overview by country  -->
            <orders-overview
              :orders="orders"
              :show-force-reload="false"
            >
              <b-form-group>
                <country-select
                  v-model="country"
                  class="w-100 my-1"
                  @input="onSelectCountry"
                />
              </b-form-group>
            </orders-overview>
          </b-col>
          <b-col cols="12">
            <!-- top users with withdrawal - spend  -->
            <withdrawal-per-spend-table />
          </b-col>
        </b-row>
      </b-col>

      <b-col
        xl="5"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <top-list
              :items="statistics.highest_spenders"
              :loading="loadingAll"
              title="Highest spenders"
              export-tooltip="Export users who have purchased at least one account."
              :show-export-btn="true"
              @onExport="onExportSpenders"
              @force-reload="getMainStats(true)"
            />
          </b-col>
          <b-col cols="12">
            <top-list
              :loading="loadingAll"
              :items="statistics.highest_withdrawals"
              title="Highest withdrawals"
              @force-reload="getMainStats(true)"
            />
          </b-col>
          <b-col cols="12">
            <report />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import QuickStatistics from '@/views/statistics/withdrawal-statistics/components/QuickStatistics.vue'
import WithdrawalPerSpendTable from '@/views/statistics/withdrawal-statistics/components/WithdrawalPerSpendTable.vue'
import OrdersOverview from '@/components/page/orders/OrdersOverview.vue'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import StatisticsAPI from '@/api/statistics'
import ExportAPI from '@/api/export'
import TopList from '@/views/statistics/withdrawal-statistics/components/TopList.vue'
import { formatCurrency } from '@/plugins/formaters'
import SpendPerCountry from '@/views/statistics/withdrawal-statistics/components/SpendPerCountry.vue'
import Report from '@/views/statistics/withdrawal-statistics/components/Report.vue'

export default {
  components: {
    SpendPerCountry,
    TopList,
    WithdrawalPerSpendTable,
    QuickStatistics,
    OrdersOverview,
    CountrySelect,
    Report,
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      statistics: {
        highest_spenders: [],
        highest_withdrawals: [],
        spenders_by_country: [],
      },
      loading: false,
      loadingAll: false,
      country: null,
      ordersLoading: false,
      orders: [],
    }
  },
  beforeMount() {
    this.getMainStats(false)
  },
  methods: {
    formatCurrency,
    getMainStats(force = false) {
      this.loadingAll = true
      StatisticsAPI.getWithdrawalStatistic(force)
        .then(response => {
          this.statistics = response
          this.loadingAll = false
        })
    },
    onExportSpenders() {
      ExportAPI.exportSpenders()
    },
    onSelectCountry() {
      if (this.country === null) return

      this.ordersLoading = true
      StatisticsAPI.getSalesByCountry(this.country).then(response => {
        this.orders = response
        this.ordersLoading = false
      })
    },
  },
}
</script>
