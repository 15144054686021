<template>
  <b-card>
    <b-card-header>
      <b-card-title>Users with highest/lowest withdrawal sum over their spend</b-card-title>
      <b-button
        variant="outline-primary"
        class="btn-icon my-50"
        @click="onExport"
      >
        Export high spending and low withdrawing users
      </b-button>
    </b-card-header>

    <b-row class="justify-content-between mb-1">
      <b-col lg="6">
        <b-button-toolbar class="my-25">
          <b-button-group>
            <b-button
              variant="secondary"
              @click="advancedFilter.profitSort = 'ASC'"
            >
              Big withdraw
            </b-button>
            <b-button
              variant="primary"
              @click="advancedFilter.profitSort = 'DESC'"
            >
              Big spenders
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
      <b-col lg="6">
        <b-form-group
          class="my-25"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by email or name"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-card-body>
      <data-table
        ref="withdrawalSpendTable"
        :module-name="'withdrawalSpendList'"
        :fields="fields"
        :filters="advancedFilter"
        sort-by="profit"
        :sort-desc="sortByDesc"
      >
        <template #cell(user)="data">
          <b-link :to="{ name: 'user-detail', params: { id: data.item.id } }">
            {{ data.item.firstName }} {{ data.item.lastName }}<br>
            <small>{{ data.item.email }}</small>
          </b-link>
        </template>

        <template #cell(total_spend)="data">
          {{ formatCurrency(data.item.spend, 'USD') }}
        </template>

        <template #cell(total_withdraw)="data">
          {{ formatCurrency(data.item.withdraw, 'USD') }}
        </template>

        <template #cell(profit)="data">
          {{ formatCurrency(data.item.profit, 'USD') }}
        </template>

        <template #cell(accounts_sum)="data">
          {{ data.item.account_count }}
        </template>

      </data-table>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BLink, BRow, BCol, BFormGroup, BInputGroup, BFormInput,
  BInputGroupAppend, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatCurrency } from '@/plugins/formaters'
import ExportAPI from '@/api/export'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButtonToolbar,
    BButtonGroup,
    DataTable,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: 'profitable',
      fields: [
        {
          key: 'user',
          sortable: false,
        },
        {
          key: 'total_spend',
          sortable: false,
        },
        {
          key: 'total_withdraw',
          sortable: false,
        },
        {
          key: 'profit',
          sortable: false,
        },
        {
          key: 'accounts_sum',
          sortable: false,
        },
      ],
      sortByDesc: true,
      advancedFilter: {
        search: '',
        profitSort: 'ASC',
      },
      search: '',
    }
  },
  watch: {
    sortByDesc() {
      this.$refs.withdrawalSpendTable.reloadAll()
    },
  },
  methods: {
    formatCurrency,
    onExport() {
      ExportAPI.exportHighSpendLowWithdrawUsers()
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
