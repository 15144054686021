<template>
  <b-overlay
    :show="loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title>{{ title }}</b-card-title>
        <b-button
          v-if="showExportBtn"
          v-b-tooltip.hover.leftbottom="exportTooltip"
          variant="outline-primary"
          class="btn-icon mb-auto float-right ml-auto mr-1"
          @click="$emit('onExport')"
        >
          <feather-icon
            icon="ExternalLinkIcon"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="float-right"
          variant="outline-primary"
          @click="$emit('force-reload')"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body>
        <div
          v-for="(item, key) in itemsArr"
          :key="key"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside v-if="!isMobile">
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <b-img
                  v-if="item.profile_image"
                  class="w-100"
                  :src="getProfileImage(item)"
                />
                <feather-icon
                  v-else
                  icon="UserIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <b-link :to="{name: 'user-detail', params: {id: item.id}}">
                <h6 class="transaction-title">
                  {{ item.firstName }} {{ item.lastName }}
                </h6>
                <small>{{ item.email }}</small>
              </b-link>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder mr-2"
          >
            <b-row>
              {{ formatCurrency(item.result_sum, item.currency || 'usd') }}
            </b-row>
          </div>
        </div>
        <b-row
          class="text-center"
          align-v="center"
        >
          <b-col>
            <b-button
              variant="outline-primary"
              class="btn-icon mb-auto"
              @click="showAll = !showAll"
            >
              <feather-icon
                v-if="!showAll"
                icon="PlusIcon"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BAvatar, BMedia, BMediaAside, BMediaBody, BRow, BCol, BImg, BButton,
  BLink, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BRow,
    BCol,
    BImg,
    BButton,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    items: Array,
    title: String,
    loading: Boolean,
    exportTooltip: String,
    showExportBtn: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    ...get('app', ['isMobile']),
    itemsArr() {
      return this.showAll ? this.items : this.items.slice(0, 3)
    },
  },
  methods: {
    formatCurrency,
    getProfileImage(item) {
      return `${process.env.VUE_APP_API_URL}users/${item.id}/avatar/${item.profile_image}`
    },
  },
}
</script>
