import axiosInstance from '@/plugins/axios'

export default {
  exportHighSpendLowWithdrawUsers() {
    const url = 'admin/tools/export/high-spend-low-withdraw-users'

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'high_spending_low_withdrawing_users.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportSpenders() {
    const url = 'admin/tools/export/spenders'

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'spenders.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportUsers(countryId) {
    const url = `admin/tools/export/users/country/${countryId}`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'users.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportUsersBySale(params) {
    const url = 'admin/tools/export/users/by-sale'

    return axiosInstance.post(url, params, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'users_by_sales.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportTradingHistory(params) {
    const url = 'admin/tools/export/trading-history'

    return axiosInstance.post(url, params, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'trading-history.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportDiscount(discountId) {
    const url = `admin/tools/export/discounts/${discountId}`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'discountExport.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportSalesByProducts(params) {
    const url = 'admin/tools/export/products'

    return axiosInstance.post(url, params, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'products_report.csv')
        document.body.appendChild(link)
        link.click()
      })
  },

  exportTopPayouts(params) {
    const url = 'admin/tools/export/top-payouts'

    return axiosInstance.post(url, params, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'top_payouts.csv')
        document.body.appendChild(link)
        link.click()
      })
  },
}
